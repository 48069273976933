export const trendTimeDailyPreset = {
  rows: {
    header: [11, 12],
  },
  columns: {
    width: [25, 10, ...Array(16).fill(12)],
    end: 'U',
  },
  table: {
    start: 11,
    end: 42,
    dataStart: 13,
  },
  totalrow: true,
  bordered: true,
};
//
export const trendTimePercentagePreset = {
  rows: {
    header: [11, 12],
  },
  columns: {
    width: [25, 10, ...Array(16).fill(12)],
    end: 'G',
  },
  table: {
    start: 11,
    end: 42,
    dataStart: 13,
  },
  totalrow: true,
  bordered: true,
};
//
export const trendTimeCumulativePreset = {
  rows: {
    header: [11, 12],
  },
  columns: {
    width: [25, 10, ...Array(16).fill(12)],
    end: 'L',
  },
  table: {
    start: 11,
    end: 42,
    dataStart: 13,
  },
  totalrow: true,
  bordered: true,
};
//
export const trendTimeWeeklyPreset = {
  rows: {
    header: [11, 12],
  },
  columns: {
    width: [25, 10, ...Array(16).fill(12)],
    end: 'U',
  },
  table: {
    start: 11,
    end: 42,
    dataStart: 13,
  },
  totalrow: true,
  bordered: true,
};
//
export const cmatTimeDailyPreset = {
  rows: {
    header: [11, 12],
  },
  columns: {
    width: [25, 10, ...Array(6).fill(12)],
    end: 'G',
  },
  table: {
    start: 11,
    end: 42,
    dataStart: 13,
  },
  totalrow: true,
  bordered: true,
};
