/* eslint-disable import/named */
import { getHotelValuesFromList } from 'modules/common/utils/array';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUrlOrder } from 'modules/dashboard/selectors';
import { dashboardActions } from 'modules/dashboard/slice';
import { UiController, events } from 'modules/common/ui-controller';
import { trendActions } from '../slice';

/**
 * Custom hook to execute trend hotel filter command flow
 * @param {Array} hotelList
 * @param {Boolean} isLoaded
 * @param {Object} selectedHotelName
 * @param {Boolean} customBooking
 * @param {Object} selectHotelName
 * @param {Boolean} normalizeDisabled
 */
const useTrendHotelCommandFlow = (hotelList, hotelGroupList, isLoaded, isTrendPercentageChange) => {
  const dispatch = useDispatch();
  //
  const urlOrder = useSelector(selectUrlOrder);
  //
  const [hotelValue, setHotelValue] = useState(null);
  const [hotelGroupCommand, setHotelGroupCommand] = useState(null);
  const [normalizeCommandValue, setNormalizedCommandValue] = useState(null);

  useEffect(() => {
    if (hotelValue !== null && hotelList?.length > 0 && isLoaded && urlOrder === 5) {
      const hotelsSelected = getHotelValuesFromList(hotelValue, hotelList);
      if (hotelsSelected?.length > 0) {
        dispatch(trendActions.setEnableSecondaryHotelGroups(false));
        dispatch(trendActions.setTrendHotelFocusOn(hotelsSelected[0]));
      }
      setHotelValue(null);
      setTimeout(() => {
        dispatch(dashboardActions.setUrlOrder(6));
      }, 4000);
    }
  }, [hotelValue, hotelList?.length, isLoaded, urlOrder]);
  // execute set hotel ui controller command flow
  useEffect(() => {
    if (hotelGroupCommand !== null && hotelGroupList?.length > 0 && isLoaded && urlOrder === 5) {
      const hotelGroupsSelected = getHotelValuesFromList(hotelGroupCommand, hotelGroupList);
      if (hotelGroupsSelected?.length > 0) {
        dispatch(trendActions.setEnableSecondaryHotelGroups(true));
        dispatch(trendActions.setTrendHotelFocusOn(hotelGroupsSelected[0]));
      }
      setHotelGroupCommand(null);
      setTimeout(() => {
        dispatch(dashboardActions.setUrlOrder(6));
      }, 4000);
    }
  }, [hotelGroupCommand, hotelGroupList?.length, isLoaded, urlOrder]);
  // Listener function to set compare hotels command value
  const setCommandValue = (data) => {
    setHotelValue(data);
  };
  // Listener function to set  normalized view command value
  const setNormalizedViewValue = (data) => {
    setNormalizedCommandValue(data);
  };
  // Listener function to set  trend hotel command value
  const setTrendHotelGroupsHandler = (data) => {
    setHotelGroupCommand(data);
  };

  // Triggers when normalizeDisabled, normalizeCommandValue, isLoaded values change
  useEffect(() => {
    if (
      normalizeCommandValue !== null &&
      isLoaded &&
      urlOrder === 16
    ) {
      // execute dispatch function to set normalized view with command value
      dispatch(trendActions.setTrendHotelNormalizedView(normalizeCommandValue === 1));
      setNormalizedCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(18));
    }
  }, [normalizeCommandValue, isLoaded, isTrendPercentageChange, urlOrder]);
  // Add event listener function to set normalize view command value
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_HOTEL_NORMALIZED_VIEW, setNormalizedViewValue);
    return () => {
      UiController.unsubscribe(events.SET_TREND_HOTEL_NORMALIZED_VIEW, setNormalizedViewValue);
    };
  }, []);
  // Add event listener function to set hotel groups command value
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_HOTEL_GROUP, setTrendHotelGroupsHandler);
    return () => {
      UiController.unsubscribe(events.SET_TREND_HOTEL_GROUP, setTrendHotelGroupsHandler);
    };
  }, []);
  // Add event listener function to set compare hotels command value
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_HOTELS_COMPARE_HOTELS, setCommandValue);
    return () => {
      UiController.unsubscribe(events.SET_TREND_HOTELS_COMPARE_HOTELS, setCommandValue);
    };
  }, []);
};
//
export default useTrendHotelCommandFlow;
