import { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TAB_VALUE } from 'modules/dashboard/constants';
import styles from 'modules/dashboard/components/tab-container/workbook-report/workbook-report.module.scss';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
  selectEnableHotelGroups,
  selectHotelName,
  selectTargetDate,
  selectVisualFilters,
  selectWorkbookDateType,
} from 'modules/dashboard/selectors';
import { getVisualName } from 'modules/dashboard/functions';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { Loader } from 'modules/common/components';
import { isEmpty } from 'modules/common/helpers/object';
import { toast } from 'react-toastify';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import generateFilterString from 'modules/dashboard/utils/filters-transformation';
import WorkBookFilter from '../filter-panel';
import useWorkbookWidget from '../../hooks/use-workbook-widget';
import {
  selectPdfExportedObject,
  selectWorkbookFilterList,
  selectLoader,
  selectD1SetIsDisplay,
  selectD2SetIsDisplay,
  selectD3SetIsDisplay,
  selectWorkbookHotelVisual,
  selectHotelFocusOn,
  selectEnableSecondaryHotelGroups,
  selectWorkbookHotelComparison,
  selectWorkbookHotelFilterList,
  selectIsRatePlan,
  selectRatePlanVisuals,
  selectRatePlanFilterList,
  selectSymmetricComparisonToggle,
} from '../../selectors';
import { workbookActions } from '../../slice';
// import VisualGridWorkbook from '../../../visual-grid-workbook';
import { NotificationWindow } from './components';
import VisualGrid from '../../../visual-grid';
import ExportToExcel from '../../../export-visual-data/export-xlsx';
import {
  getDateSwitchSchema,
  getSymmetricComparisonSchema,
  getWorkbookTargetDateSchema,
} from '../../../inner-filter/functions';

/**
 * Workbook widget report with filters
 * @param {Number} value - Workbook widget tab value
 * @param {String} accessToken - Power BI access token
 * @param {Object} visuals - Workbook tab visual details
 * @param {Boolean} active - whether Workbook tab is active
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {String} reportType - Report type of active tab
 * @returns
 */

const WorkbookReport = ({
  value,
  accessToken,
  visuals,
  active,
  latestDate,
  reportType,
  hotelTimezone,
  toggleDisabled,
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(selectOrganizationId);
  const exportPdfDetails = useSelector(selectPdfExportedObject);
  const loading = useSelector(selectLoader);
  //
  useWorkbookWidget();
  // States are obtained from redux store. Refer each selector function for details.
  const selectedHotel = useSelector(selectHotelName);
  const hotelVisuals = useSelector(selectWorkbookHotelVisual);
  const ratePlanVisuals = useSelector(selectRatePlanVisuals);
  const hotelFocusOn = useSelector(selectHotelFocusOn);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const isWorkbookHotelComparison = useSelector(selectWorkbookHotelComparison);
  const isRatePlan = useSelector(selectIsRatePlan);
  const ratePlanFilterList = useSelector(selectRatePlanFilterList);
  const workbookFilterList = useSelector(selectWorkbookFilterList);
  const workbookHotelFilterList = useSelector(selectWorkbookHotelFilterList);
  const d1SetIsDisplay = useSelector(selectD1SetIsDisplay);
  const d2SetIsDisplay = useSelector(selectD2SetIsDisplay);
  const d3SetIsDisplay = useSelector(selectD3SetIsDisplay);
  //
  const visualFilters = useSelector(selectVisualFilters);
  const selectedDateType = useSelector(selectWorkbookDateType);
  const targetDate = useSelector(selectTargetDate);
  const symmetricComparisonToggle = useSelector(selectSymmetricComparisonToggle);

  // Local states to set Workbook visual loading states
  const [isD1Loaded, setIsD1Loaded] = useState(false);
  const [isD2Loaded, setIsD2Loaded] = useState(false);
  const [isD3Loaded, setIsD3Loaded] = useState(false);
  const [isWorkbookLoaded, setIsWorkbookLoaded] = useState(false);
  const [isD1Rendered, setIsD1Rendered] = useState(false);
  const [isD2Rendered, setIsD2Rendered] = useState(false);
  const [isD3Rendered, setIsD3Rendered] = useState(false);
  const [workbookReport, setWorkbookReport] = useState({});
  const [pageName, setPageName] = useState('ReportSection75724c995dd4766cdd07');

  // Generate filter for stay/arrival dates
  const targetDateFilter = getWorkbookTargetDateSchema(
    visualFilters,
    selectedDateType?.id,
    targetDate?.startDate,
    targetDate?.endDate
  );
  // generate date switch filter
  const dateSwitchFilter = getDateSwitchSchema(visualFilters, selectedDateType?.id);
  // generate symmetric comparison filter
  const symmetricComparisonFilter = getSymmetricComparisonSchema(
    visualFilters,
    symmetricComparisonToggle
  );
  // To get visuals based on the configuration
  useEffect(() => {
    // To enable hotel comparison view based on selected hotels
    if (hotelFocusOn?.id !== selectedHotel?.id) {
      dispatch(workbookActions.setIsWorkbookHotelComparison(true));
    } else if (enableSecondaryHotelGroups && !enableHotelGroups) {
      dispatch(workbookActions.setIsWorkbookHotelComparison(true));
    } else if (!enableSecondaryHotelGroups && enableHotelGroups) {
      dispatch(workbookActions.setIsWorkbookHotelComparison(true));
    } else {
      dispatch(workbookActions.setIsWorkbookHotelComparison(false));
    }
  }, [enableHotelGroups, enableSecondaryHotelGroups, selectedHotel, hotelFocusOn]);
  // To get visual details based on index
  const getVisual = (index) => {
    let found;
    if (isRatePlan) {
      found = ratePlanVisuals?.find((visual) => visual?.order === index);
    } else if (isWorkbookHotelComparison) {
      found = hotelVisuals?.find((visual) => visual?.order === index);
    } else {
      found = visuals?.find((visual) => visual?.order === index);
    }
    return found;
  };
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    setIsRendered = () => {},
    filter = workbookFilterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
          setIsRendered={(d) => setIsRendered(d)}
        />
      )
    );
  };
  useEffect(() => {
    if (
      hotelFocusOn?.id !== selectedHotel?.id ||
      (enableSecondaryHotelGroups && !enableHotelGroups) ||
      (!enableSecondaryHotelGroups && enableHotelGroups)
    ) {
      setPageName('ReportSection2ddc6e9660c4801addc1');
    } else {
      setPageName('ReportSection75724c995dd4766cdd07');
    }
  }, [hotelFocusOn, selectedHotel, enableSecondaryHotelGroups, enableHotelGroups]);
  //
  const exportReport = async () => {
    // Export the captured bookmark to PDF
    const capturedBookmark = await workbookReport.bookmarksManager.capture();
    //
    const settings = {
      format: 'PDF',
      powerBIReportConfiguration: {
        defaultBookmark: {
          state: capturedBookmark.state,
        },
        pages: [{ pageName }],
        reportLevelFilters: [
          {
            filter: generateFilterString([
              targetDateFilter,
              dateSwitchFilter,
              symmetricComparisonFilter,
            ]),
          },
        ],
      },
    };
    //
    dispatch(
      workbookActions.getWorkbookPdfExportUrl({
        settings,
        organizationId,
        reportId: workbookReport.config.id,
        groupId: workbookReport.config.groupId,
        isWorkbookHotelComparisonEnable: isWorkbookHotelComparison,
        labels: isWorkbookHotelComparison
          ? {
              label1: `Selection Hotel ${
                selectedHotel && 'hotels' in selectedHotel ? 'Set' : ''
              } (A) : ${selectedHotel?.label}`,
              label2: `Comparison Hotel ${
                hotelFocusOn && 'hotels' in hotelFocusOn ? 'Set' : ''
              } (B) : ${hotelFocusOn?.label}`,
            }
          : {
              label1: `Hotel ${selectedHotel && 'hotels' in selectedHotel ? 'Set' : ''} : ${
                selectedHotel?.label
              }`,
            },
      })
    );
  };
  //
  useEffect(() => {
    if (exportPdfDetails && !isEmpty(workbookReport) && value === TAB_VALUE.WORKBOOK_REPORT) {
      toast(exportPdfDetails?.message, { type: TOAST_TYPES.SUCCESS });
    }
  }, [exportPdfDetails]);
  //
  useEffect(() => {
    // Calculate is Loaded  based on the given conditions
    const pair1 = d1SetIsDisplay ? d1SetIsDisplay && isD1Loaded : true;
    const pair2 = d2SetIsDisplay ? d2SetIsDisplay && isD2Loaded : true;
    const pair6 = d3SetIsDisplay ? d3SetIsDisplay && isD3Loaded : true;
    if (
      d1SetIsDisplay &&
      isD1Loaded &&
      d2SetIsDisplay &&
      isD2Loaded &&
      d3SetIsDisplay &&
      isD3Loaded
    ) {
      setIsWorkbookLoaded(true);
    } else {
      setIsWorkbookLoaded(pair1 || pair2 || pair6);
    }
  }, [d1SetIsDisplay, isD1Loaded, d2SetIsDisplay, isD2Loaded, d3SetIsDisplay, isD3Loaded]);
  //
  return (
    active && (
      <Loader loading={loading}>
        <Grid
          container
          direction="row"
          className={styles.mainGrid}
          sx={{
            display: value === TAB_VALUE.WORKBOOK_REPORT ? '' : 'none',
          }}
        >
          <Grid
            item
            xs={2}
            cc={2.5}
            md={2}
            sx={{
              ml: {
                xs: 0,
                cc: 0.25,
                md: 0,
              },
            }}
          >
            <WorkBookFilter
              reportType={reportType}
              visual={getWidget(100, styles.symmetricComparisonWindow)}
              visualName={getVisualName(visuals, 100)}
              latestDate={latestDate}
              hotelTimezone={hotelTimezone}
              isLoaded={isWorkbookLoaded}
              toggleDisabled={toggleDisabled}
            />
          </Grid>
          <Grid
            container
            item
            xs={10}
            cc={9.25}
            md={10}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid mb={2} container item justifyContent="flex-end" alignItems="center">
              {isD1Loaded && isD2Loaded && isD3Loaded && !isRatePlan && (
                <IconButton
                  size="small"
                  onClick={exportReport}
                  disabled={!isD1Rendered || !isD2Rendered || !isD3Rendered}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      width: '1.5rem',
                      height: '1.25rem',
                      marginRight: 2,
                    },
                  }}
                >
                  <GetAppIcon />
                </IconButton>
              )}
              <NotificationWindow />
              {/*  */}
            </Grid>
            {isRatePlan && !isEmpty(ratePlanFilterList) && (
              <Box sx={{ flexGrow: 1 }}>
                <VisualGrid
                  isDisplay={d1SetIsDisplay}
                  order={0}
                  visualsList={ratePlanVisuals}
                  visual={getWidget(
                    0,
                    styles.workbookReportD1RatePlan,
                    setWorkbookReport,
                    setIsD1Loaded,
                    setIsD1Rendered,
                    ratePlanFilterList
                  )}
                  style={styles.workbookVisualGrid}
                  onClick={() => {
                    dispatch(workbookActions.setD1SetIsDisplay(!d1SetIsDisplay));
                  }}
                  toggleFragment={
                    isD1Rendered ? (
                      <ExportToExcel
                        report={workbookReport}
                        isLoaded={isD1Rendered}
                        fileName="Rate_Plan"
                      />
                    ) : (
                      ''
                    )
                  }
                />
              </Box>
            )}
            {!isRatePlan && isWorkbookHotelComparison && !isEmpty(workbookHotelFilterList) ? (
              <Box sx={{ flexGrow: 1 }}>
                <VisualGrid
                  isDisplay={d1SetIsDisplay}
                  order={0}
                  visualsList={hotelVisuals}
                  visual={getWidget(
                    0,
                    styles.workbookReportD1,
                    setWorkbookReport,
                    setIsD1Loaded,
                    setIsD1Rendered,
                    workbookHotelFilterList
                  )}
                  style={styles.workbookVisualGrid}
                  onClick={() => {
                    dispatch(workbookActions.setD1SetIsDisplay(!d1SetIsDisplay));
                  }}
                  isWorkbookHotelComparison={isWorkbookHotelComparison}
                  comparisonHotel={hotelFocusOn}
                  displayHotelName
                />
                <VisualGrid
                  isDisplay={d3SetIsDisplay}
                  order={2}
                  visualsList={hotelVisuals}
                  visual={getWidget(
                    2,
                    styles.workbookReportD3,
                    () => {},
                    setIsD3Loaded,
                    setIsD3Rendered,
                    workbookHotelFilterList
                  )}
                  style={styles.workbookVisualGrid}
                  onClick={() => {
                    dispatch(workbookActions.setD3SetIsDisplay(!d3SetIsDisplay));
                  }}
                  isWorkbookHotelComparison={isWorkbookHotelComparison}
                  comparisonHotel={hotelFocusOn}
                />
                <VisualGrid
                  isDisplay={d2SetIsDisplay}
                  order={1}
                  visualsList={hotelVisuals}
                  visual={getWidget(
                    1,
                    styles.workbookReportD2,
                    () => {},
                    setIsD2Loaded,
                    setIsD2Rendered,
                    workbookHotelFilterList
                  )}
                  style={styles.workbookVisualGrid}
                  onClick={() => {
                    dispatch(workbookActions.setD2SetIsDisplay(!d2SetIsDisplay));
                  }}
                  isWorkbookHotelComparison={isWorkbookHotelComparison}
                  comparisonHotel={hotelFocusOn}
                />
              </Box>
            ) : (
              !isRatePlan &&
              !isEmpty(workbookFilterList) && (
                <Box sx={{ flexGrow: 1 }}>
                  <VisualGrid
                    isDisplay={d1SetIsDisplay}
                    order={0}
                    visualsList={visuals}
                    visual={getWidget(
                      0,
                      styles.workbookReportD1,
                      setWorkbookReport,
                      setIsD1Loaded,
                      setIsD1Rendered
                    )}
                    style={styles.workbookVisualGrid}
                    onClick={() => {
                      dispatch(workbookActions.setD1SetIsDisplay(!d1SetIsDisplay));
                    }}
                    isWorkbookHotelComparison={isWorkbookHotelComparison}
                    comparisonHotel={hotelFocusOn}
                    displayHotelName
                  />
                  <VisualGrid
                    isDisplay={d3SetIsDisplay}
                    order={2}
                    visualsList={visuals}
                    visual={getWidget(
                      2,
                      styles.workbookReportD3,
                      () => {},
                      setIsD3Loaded,
                      setIsD3Rendered
                    )}
                    style={styles.workbookVisualGrid}
                    onClick={() => {
                      dispatch(workbookActions.setD3SetIsDisplay(!d3SetIsDisplay));
                    }}
                    isWorkbookHotelComparison={isWorkbookHotelComparison}
                    comparisonHotel={hotelFocusOn}
                  />
                  <VisualGrid
                    isDisplay={d2SetIsDisplay}
                    order={1}
                    visualsList={visuals}
                    visual={getWidget(
                      1,
                      styles.workbookReportD2,
                      () => {},
                      setIsD2Loaded,
                      setIsD2Rendered
                    )}
                    style={styles.workbookVisualGrid}
                    onClick={() => {
                      dispatch(workbookActions.setD2SetIsDisplay(!d2SetIsDisplay));
                    }}
                    isWorkbookHotelComparison={isWorkbookHotelComparison}
                    comparisonHotel={hotelFocusOn}
                  />
                </Box>
              )
            )}
          </Grid>
        </Grid>
      </Loader>
    )
  );
};
//
export default WorkbookReport;
