/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsCumulative,
  selectTrendTimeWeeklyView,
  selectTrendSymmetricComparisonToggle,
  selectTrendTimeAlignmentToggle,
  selectRevenueIsDisplayTT,
  selectTrendTimeSegmentTabularVisuals,
  selectTrendTimeSegmentWeeklyTabularVisuals,
  selectTrendTimeSegmentCumulativeTabularVisuals,
  selectTrendTimeTabularFilterList,
  selectTrendHotelFocusOn,
  selectActiveTrendTab,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import ExportToCSV from 'modules/dashboard/components/tab-container/export-visual-data/export-csv';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import { isEmpty } from '../../../../../../../../common/helpers/object';
/**
 * Trend Time tabular visual report with filters
 */
const TrendTimeSegmentTabularWidget = ({setIsTimeLoaded}) => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const tabularFilterList = useSelector(selectTrendTimeTabularFilterList);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const isCumulative = useSelector(selectIsCumulative);
  const alignmentToggle = useSelector(selectTrendTimeAlignmentToggle);
  const symmetricComparisonToggle = useSelector(selectTrendSymmetricComparisonToggle);
  const tokenDetails = useSelector(selectEmbedToken);
  const visuals = useSelector(selectTrendTimeSegmentTabularVisuals);
  const weeklyVisuals = useSelector(selectTrendTimeSegmentWeeklyTabularVisuals);
  const cumulativeVisuals = useSelector(selectTrendTimeSegmentCumulativeTabularVisuals);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayTT);
  const hotelFocusOn = useSelector(selectTrendHotelFocusOn);
  const activeTab = useSelector(selectActiveTrendTab);

  const [tabularCombined, setTabularCombined] = useState({});
  const [isTabularCombinedRendered, setIsTabularCombinedRendered] = useState({});
  //
  const trendFilters = `Alignment : , ${
    alignmentToggle ? 'On' : 'Off'
  }\r\nSymmetric Comparison : , ${symmetricComparisonToggle ? 'On' : 'Off'}\r\n`;
  // To get visual details
  const getVisual = (index) => {
    let found;
    if (isCumulative) {
      found = cumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (weeklyViewToggle) {
      found = weeklyVisuals?.find((visual) => visual?.order === index);
    } else {
      found = visuals?.find((visual) => visual?.order === index);
    }
    return found;
  };
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = ()=>{},
    setIsRendered = () => {},
    filter = tabularFilterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded = {(r)=>setIsLoaded(r)}
          setIsRendered={(d) => setIsRendered(d)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  return (
    !isEmpty(tabularFilterList) && (
      <VisualGrid
        isDisplay={revenueIsDisplay}
        visualName={getVisualName(0)}
        visual={getWidget(
          0,
          styles.tabularVisual,
          setTabularCombined,
          setIsTimeLoaded,
          setIsTabularCombinedRendered
        )}
        onClick={() => {
          dispatch(trendActions.setRevenueIsDisplayTT(!revenueIsDisplay));
        }}
        toggleFragment={
          <ExportToCSV
            report={tabularCombined}
            isLoaded={isTabularCombinedRendered}
            fileName={
              isCumulative ? 'TXS_Cumulative' : weeklyViewToggle ? 'TXS_Weekly' : 'TXS_Daily'
            }
            weeklyViewToggle={weeklyViewToggle}
            isCumulative={isCumulative}
            otherFilters={trendFilters}
          />
        }
        isWorkbookHotelComparison={activeTab === REPORT_TYPE.TREND_HOTEL}
        comparisonHotel={hotelFocusOn}
        displayHotelName
      />
    )
  );
};
//
export default TrendTimeSegmentTabularWidget;
