/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  ListItem,
  useMediaQuery,
  Grid,
  TextField,
  InputAdornment,
  Paper,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import {
  selectCommandFilterLoader,
  selectHotelChangeState,
  selectIsSavedCommandFilterLoaded,
  selectLandingPage,
  selectSavedCommandFilters,
  selectTabChangeState,
} from 'modules/dashboard/selectors';
import { dashboardActions } from 'modules/dashboard/slice';
import {
  EXECUTION_TYPE,
  UiController,
  consoleCommands,
  decodedTabs,
} from 'modules/common/ui-controller';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { Search, Trash } from 'react-feather';
import { isEmpty } from 'modules/common/helpers/object';
import { AddToPhotos } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganizationId, selectAuthUser } from 'modules/common/auth/selectors';
import { transformIntoArray } from 'modules/common/utils/array';
import { executeMultipleCommands } from 'modules/common/ui-controller/utils/functions';
import { Modal } from 'modules/common/components';
import {
  NotificationHeader,
  Popover,
  ScrollList,
} from 'modules/dashboard/components/tab-container/share/components/filter-dropdown/style';
import { TABS } from 'modules/common/ui-controller/utils/constants';
/**
 * Filter item component
 * @param {*} index
 * @param {*} filter
 * @param {*} applyFilter
 * @param {*} setOpen
 * @param {*} setDeleteFilterName
 * @param {Object} landingPage
 * @returns
 */
const Filter = ({ index, filter, applyFilter, setOpen, setDeleteFilterName, landingPage }) => (
  <ListItem
    divider
    component={Link}
    to="#"
    key={index}
    sx={{
      justifyContent: 'space-between',
    }}
  >
    <Grid
      item
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 200,
      }}
      alignSelf="center"
    >
      <Tooltip title={filter?.filterName}>
        <Typography noWrap color="textPrimary" variant="subtitle2">
          {filter?.filterName}
        </Typography>
      </Tooltip>
    </Grid>
    <Grid container flexDirection="row" justifyContent="flex-end">
      <IconButton onClick={() => applyFilter(filter.query)}>
        <AddToPhotos size={16} />
      </IconButton>
      <Tooltip
        title={
          landingPage?.id === filter?.filterName
            ? 'Cannot delete default landing page filter. Please reset default landing page and try again'
            : ''
        }
      >
        <span>
          <IconButton
            disabled={landingPage?.id === filter?.filterName}
            onClick={() => {
              setOpen(true);
              setDeleteFilterName(filter.filterName);
            }}
          >
            <Trash size={16} />
          </IconButton>
        </span>
      </Tooltip>
    </Grid>
  </ListItem>
);
/**
 * Filter dropdown component to list saved command filters
 * @param {Boolean} isOpen
 * @param {*} setOpen
 */
const FilterDropdown = ({ isOpen, setOpen, innerRef }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const userId = useSelector(selectAuthUser);
  const landingPage = useSelector(selectLandingPage);
  const filterLoader = useSelector(selectCommandFilterLoader);
  const filterList = useSelector(selectSavedCommandFilters);
  const tabChange = useSelector(selectTabChangeState);
  const hotelChange = useSelector(selectHotelChangeState);
  const IsSavedCommandFilterLoaded = useSelector(selectIsSavedCommandFilterLoaded);
  const [commandSet, setCommandSet] = useState('');
  //
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('md'));
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [filterNameToBeDeleted, setFilterNameToBeDeleted] = useState('');
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  //
  const handleClose = () => {
    setOpen(false);
    setSearchTerm('');
  };
  //
  const deleteFilter = (filterName) => {
    const query = `filterName=${filterName?.trim()}`;
    dispatch(
      dashboardActions.deleteCommandFilter({
        organizationId,
        userId: userId?.id,
        query,
      })
    );
  };
  //
  const applyFilter = (url) => {
    dispatch(dashboardActions.setIsSavedCommandFilterLoaded(true));
    const [search, hash] = url.split('#');
    if (hash) {
      const tab = decodedTabs[hash];
      if (tab !== undefined) {
        UiController.setTab(tab);
        dispatch(dashboardActions.setUrlTab(TABS[tab]));
      }
    }
    //
    if (search) {
      setCommandSet(search);
      const commands = search?.split('&');
      const hotel = commands?.filter((com) => com.includes(consoleCommands.HOTEL));
      const hotelGroup = commands?.filter((com) => com.includes(consoleCommands.HOTEL_GROUP));
      if (hotel?.length > 0) {
        const values = transformIntoArray(hotel[0]?.split('=')[1]);
        UiController.setHotelName(values);
      } else if (hotelGroup?.length > 0) {
        const groupValues = transformIntoArray(hotelGroup[0]?.split('=')[1]);
        UiController.setHotelGroupName(groupValues);
      }
    }
  };
  //
  useEffect(() => {
    if (tabChange && hotelChange && commandSet && IsSavedCommandFilterLoaded) {
      executeMultipleCommands(commandSet, EXECUTION_TYPE.CONVERSATION);
      setCommandSet('');
      dispatch(dashboardActions.setTabChange(false));
      dispatch(dashboardActions.setHotelChange(false));
      dispatch(dashboardActions.setIsSavedCommandFilterLoaded(false));
      handleClose();
    }
  }, [tabChange, hotelChange, commandSet, IsSavedCommandFilterLoaded]);
  //
  useEffect(() => {
    if (filterNameToBeDeleted && isDeleteConfirmed) {
      deleteFilter(filterNameToBeDeleted);
      setFilterNameToBeDeleted('');
      setIsDeleteConfirmed(false);
      setOpenDeleteModal(false);
      setOpen(false);
    }
  }, [filterNameToBeDeleted, isDeleteConfirmed]);
  //
  const handleScroll = (event) => {
    const scrollTotal = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
    if (
      event.currentTarget.scrollTop / scrollTotal > 0.9 &&
      filterList?.totalCount > filterList.page + 1 * filterList.limit &&
      !filterLoader
    ) {
      dispatch(
        dashboardActions.getScrollCommandFilter({
          organizationId,
          userId: userId?.id,
          query: `limit=${PAGINATION.AGGREGATION_FILTER_PER_PAGE}&page=${filterList.page + 1}`,
        })
      );
    }
  };
  //
  useEffect(() => {
    dispatch(
      dashboardActions.getCommandFilter({
        organizationId,
        userId: userId?.id,
        query: `limit=${PAGINATION.AGGREGATION_FILTER_PER_PAGE}&page=0&filterName=${searchTerm}`,
      })
    );
  }, [searchTerm]);
  //
  return (
    <Popover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      sx={{
        '.MuiPopover-paper': {
          height: isLgUp ? 320 : 100,
          overflow: 'hidden',
        },
        top: 30,
      }}
      anchorEl={innerRef.current}
      onClose={handleClose}
      open={isOpen}
    >
      <NotificationHeader>
        <Grid
          container
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          m={1}
        >
          <TextField
            placeholder="Search"
            fullWidth
            size="small"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search size={16} />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </Grid>
      </NotificationHeader>
      <Paper style={{ maxHeight: isLgUp ? 250 : 270, overflow: 'auto' }} onScroll={handleScroll}>
        {(filterList?.results?.length === 0 || isEmpty(filterList)) && (
          <Typography textAlign="center">No filters</Typography>
        )}
        <>
          <ScrollList disablePadding>
            {filterList?.results?.map((filter, index) => (
              <Filter
                key={index}
                index={index}
                filter={filter?.commandFilterQuery}
                applyFilter={applyFilter}
                setOpen={setOpenDeleteModal}
                setDeleteFilterName={setFilterNameToBeDeleted}
                landingPage={landingPage}
              />
            ))}
          </ScrollList>
          {filterLoader && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              position="absolute"
              height="100%"
              width="100%"
              top={0}
              zIndex={1000}
              my={4}
            >
              <CircularProgress color="inherit" size={40} />
            </Grid>
          )}
        </>
      </Paper>
      <Modal
        open={openDeleteModal}
        handleClose={() => {
          setFilterNameToBeDeleted('');
          setIsDeleteConfirmed(false);
          setOpenDeleteModal(false);
          setOpen(false);
        }}
        title="Delete Filter"
        content=" Are you sure you want to delete the filter?"
        handleSuccess={() => setIsDeleteConfirmed(true)}
        closeLabel="Cancel"
        successLabel="Delete"
        variant="contained"
        color="error"
      />
    </Popover>
  );
};
//
export default FilterDropdown;
