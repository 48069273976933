/**
 * Define tab command schema with related tab index
 */
export const TABS = {
  sm: 0,
  wb: 1,
  // pt: 2,
  // ps: 3,
  td: 2,
  // tt: 3,
  pa: 3,
  rm: 4,
  // wc: 5,
};
/**
 * Define compare windows command schema with related label values
 */
export const compareWindows = {
  otb: 'vs OTB',
  pw: 'vs Previous Week (OTB)',
  pm: 'vs Previous Month (OTB)',
  py: 'vs Previous Year (OTB)',
  cus: 'Custom',
};
/**
 * Define date range command schema
 */
export const commands = {
  OTB: 'otb',
  LAST_WEEK: 'lw',
  WEEK_TO_DATE: 'wtd',
  LAST_MONTH: 'lm',
  MONTH_TO_DATE: 'mtd',
  LAST_YEAR: 'ly',
  YEAR_TO_DATE: 'ytd',
  THIS_WEEK: 'tw',
  THIS_MONTH: 'tm',
  TODAY: 'tdy',
  FORWARD_7_DAYS: 'fw7',
};
/**
 * Define all command schemas that used in Ui controller flow
 */
export const consoleCommands = {
  TAB: 'tab',
  STAY_DATE: 'sty',
  HOTEL: 'htl',
  HOTEL_GROUP: 'htg',
  COMPARE_HOTEL_GROUP: 'chg',
  PMS: 'pms',
  BOOKING_DATE: 'bok',
  WEEKLY_VIEW: 'wkk',
  ALIGNMENT: 'alm',
  SYMMETRIC: 'sym',
  CUMULATIVE_VIEW: 'cum',
  COMPARE_WINDOW: 'cmp',
  PACE_HORIZON: 'bph',
  PACE_VARIABLE: 'bpv',
  SEGMENT: 'seg',
  COMPARE_HOTEL: 'cph',
  FOCUS: 'fcs',
  STAY_FORWARD_PERIOD: 'sfp',
  STAY_FORWARD_MONTH: 'sfm',
  STAY_BACKWARD_PERIOD: 'sbp',
  STAY_BACKWARD_MONTH: 'sbm',
  NORMALIZED_VIEW: 'nor',
  WEB_CLICK_STEPS: 'wcs',
  WEB_CLICK_SCALE: 'scl',
  CUSTOM_DATES: 'cdr',
  SET_A: 'setA',
  SET_B: 'setB',
  TABULAR_VIEW: 'tbv',
  METRIC_SELECTION: 'mts',
  TIME_FRAME: 'tfr',
  LAG_ALIGN: 'lag',
  BOOKING_FORECAST: 'bfc',
  PERCENTAGE: 'per',
  EXTENDING_FORECAST: 'exf',
  SYMMETRIC_COMPARISON_COLLAPSE: 'syD',
  VISUAL_LOADING: 'load',
  FORECAST: 'fct',
  ACTIVE_TREND_TAB: 'atb',
  BREAKDOWN_TOGGLE: 'bdt',
  PERCENTAGE_TOGGLE: 'pct',
  SEGMENT_BREAKDOWN: 'swb',
  UTM_BREAKDOWN: 'utm',
  UTM_FOCUS_ON: 'ufo',
  BOOKING_PACE_WINDOW: 'bpw',
  MOVING_AVERAGE: 'mov',
  CURRENCY: 'cur',
  DATE_TYPE: 'dtp',
  RATE_PLAN: 'rpl',
};
/**
 * Define pace variables command schema
 */
export const paceVariableNames = {
  rms: 'Occupied Rooms',
  rev: 'Revenue',
  adr: 'ADR',
  tra: 'Transaction Count',
};
/**
 * Define time frame command schema
 */
export const timeFrameOptions = {
  '1y': '1 year',
  '2y': '2 years',
  '6m': '6 months',
};
/**
 * Define segment breakdown command schema
 */
export const breakdownList = {
  ch: 'Channel',
  ms: 'Market Segment',
  rc: 'Rate Category',
  rg: 'Region',
};
/**
 * Define tab values for generating share url component
 */
export const encodedTabs = {
  0: 'summary',
  1: 'workbook',
  // 2: 'pace-time',
  // 3: 'pace-segment',
  2: 'trend',
  // 3: 'trend-tabular',
  3: 'pace',
  4: 'regional-map',
  // 5: 'web-clicks',
};
/**
 * Define decoded tab values schema for executing share url component
 */
export const decodedTabs = {
  summary: 'sm',
  workbook: 'wb',
  'pace-time': 'pt',
  'pace-segment': 'ps',
  'regional-map': 'rm',
  trend: 'td',
  // 'trend-tabular': 'tt',
  // 'trend-time': 'tt',
  'trend-segment': 'ts',
  'trend-hotels': 'th',
  'pace-3d': 'pd',
  // 'web-clicks': 'wc',
  // 'i-prefer': 'ip',
  pace: 'pa',
};
/**
 * Define command execution types based on where it gets called
 */
export const EXECUTION_TYPE = {
  COMMAND: 'COMMAND',
  URL: 'URL',
  CONVERSATION: 'CONVERSATION',
};
/**
 * Define tabular view types in trend tabs
 */
export const TABULAR_VIEWS = {
  TOTAL_REVENUE: 'A',
  TOTAL_OCCUPIEDROOMS: 'B',
  ADR: 'C',
  BOOKING_WINDOW: 'D',
  LENGTH_OF_STAY: 'E',
  CANCELLATIONS: 'F',
  REGIONAL_BREAKDOWN: 'G',
  CANCELLATIONS_COUNT: 'H',
};
/**
 * Define roles in chat console messages
 */
export const CHAT_ROLES = {
  USER: 'user',
  SYSTEM: 'system',
};

/**
 * Define collapsed visuals
 */
export const COLLAPSE_VISUALS = {
  PACE: 'PA',
  REVENUE: 'RV',
  CX: 'CX',
  D1: 'D1',
  D2: 'D2',
  D3: 'D3',
  TT: 'TT',
  TP: 'TP',
  ST: 'ST',
  SP: 'SP',
  HT: 'HT',
  HP: 'HP',
  UT: 'UT',
  UP: 'UP',
  TABULAR: 'T',
};
