import {
  cmatTimeDailyPreset,
  trendTimeCumulativePreset,
  trendTimeDailyPreset,
  trendTimePercentagePreset,
  trendTimeWeeklyPreset,
} from './tt-presets';
import { ratePlanPreset } from './rate-plan';
import {
  trendHotelCumulativePreset,
  trendHotelDailyPreset,
  trendHotelMovingAveragePreset,
  trendHotelNormalizedPreset,
  trendHotelPercentagePreset,
  trendHotelWeeklyPreset,
} from './th-presets';
import {
  paceSegmentCumulativeLagAlignedPreset,
  paceSegmentCumulativePreset,
  paceSegmentDailyPreset,
  paceSegmentLagAlinedPreset,
} from './ps-presets';
import {
  paceTimeCumulativeLagAlignedPreset,
  paceTimeCumulativePreset,
  paceTimeDailyPreset,
  paceTimeLagAlinedPreset,
} from './pt-presets';
import {
  cmatSegmentDailyPreset,
  trendSegmentCumulativePreset,
  trendSegmentDailyPreset,
  trendSegmentPercentagePreset,
  trendSegmentWeeklyPreset,
} from './ts-presets';
import {
  paceHotelCumulativeLagAlignedPreset,
  paceHotelCumulativePreset,
  paceHotelDailyPreset,
  paceHotelLagAlinedPreset,
} from './ph-presets';

// file names are used as key so the right preset can be selected using the filename in runtime
const powerBIVisualPresets = {
  // trend time
  TVT_Daily: trendTimeDailyPreset,
  TVT_Percentage: trendTimePercentagePreset,
  CMATT_Daily: cmatTimeDailyPreset,
  TVT_Cumulative: trendTimeCumulativePreset,
  TVT_Weekly: trendTimeWeeklyPreset,

  // trend segment
  TVS_Daily: trendSegmentDailyPreset,
  TVS_Cumulative: trendSegmentCumulativePreset,
  TVS_Weekly: trendSegmentWeeklyPreset,
  TVS_Percentage: trendSegmentPercentagePreset,
  CMATS_Daily: cmatSegmentDailyPreset,

  // trend hotel
  TVH_Daily: trendHotelDailyPreset,
  TVH_Weekly: trendHotelWeeklyPreset,
  TVH_Cumulative: trendHotelCumulativePreset,
  CMATH_Daily: trendHotelMovingAveragePreset,
  TVH_Normalized: trendHotelNormalizedPreset,
  TVH_Percentage: trendHotelPercentagePreset,

  // pace segment
  PVS_Daily: paceSegmentDailyPreset,
  PVS_Cumulative: paceSegmentCumulativePreset,
  PVS_Lag_Aligned: paceSegmentLagAlinedPreset,
  PVS_Cumulative_Lag_Aligned: paceSegmentCumulativeLagAlignedPreset,

  // pace time
  PVT_Cumulative_Lag_Aligned: paceTimeCumulativeLagAlignedPreset,
  PVT_Cumulative: paceTimeCumulativePreset,
  PVT_Lag_Aligned: paceTimeLagAlinedPreset,
  PVT_Daily: paceTimeDailyPreset,

  // pace hotel
  PVH_Cumulative_Lag_Aligned: paceHotelCumulativeLagAlignedPreset,
  PVH_Cumulative: paceHotelCumulativePreset,
  PVH_Lag_Aligned: paceHotelLagAlinedPreset,
  PVH_Daily: paceHotelDailyPreset,

  // Rate Plan
  Rate_Plan: ratePlanPreset,
};

export default powerBIVisualPresets;
