import { useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { getVisualName } from 'modules/dashboard/functions';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import { CustomToggleSwitch } from 'modules/common/components';
import PaceFilter from '../../filter-panel';
import {
  selectActivePaceTab,
  selectPaceTimeRevenueFilterList,
  selectPaceTimeVisuals,
  selectTabularView,
} from '../../../selectors';
import PaceTimeTabularWidget from './pace-time/tabular-visual-view';
import PaceTimeGraphicalWidget from './pace-time/graphical-visual-view';
import { paceActions } from '../../../slice';
import PaceHotelGraphicalWidget from './pace-hotel/graphical-visual-view';
import PaceHotelTabularWidget from './pace-hotel/tabular-visual-view';
import PaceSegmentGraphicalWidget from './pace-segment/graphical-visual-view';
import PaceSegmentTabularWidget from './pace-segment/tabular-visual-view';

/**
 * Trend tab widget implementation
 * @param {Number} value - Trend time widget tab value
 * @param {Boolean} toggleDisabled - PMS sync toggle disabled state
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {String} reportType - Report type of active tab
 * @returns
 */
const Widget = ({ value, latestDate, reportType, hotelTimezone, toggleDisabled }) => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const filterList = useSelector(selectPaceTimeRevenueFilterList);
  const tokenDetails = useSelector(selectEmbedToken);
  const visuals = useSelector(selectPaceTimeVisuals);
  const activeTab = useSelector(selectActivePaceTab);
  const tabularView = useSelector(selectTabularView);
  //
  const [isLoaded, setIsLoaded] = useState(false);

  // To get visuals based on the configuration
  const getWidget = (index, cssClassName) => {
    const found = visuals?.find((visual) => visual?.order === index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filterList}
        />
      )
    );
  };
  //
  return (
    <>
      <Grid
        item
        xs={2}
        cc={2.5}
        md={2}
        sx={{
          ml: {
            xs: 0,
            cc: 0.25,
            md: 0,
          },
        }}
      >
        <PaceFilter
          reportType={reportType}
          tabValue={value}
          toggleDisabled={toggleDisabled}
          latestDate={latestDate}
          hotelTimezone={hotelTimezone}
          isLoaded={isLoaded}
          visual={getWidget(100, styles.symmetricComparisonWindow)}
          visualName={getVisualName(visuals, 100)}
        />
      </Grid>
      <Grid container item xs={10} cc={9.25} md={10} direction="column">
        <Grid item container justifyContent="flex-end" xs={12}>
          <CustomToggleSwitch
            onChange={(event) => dispatch(paceActions.setTabularView(event.target.checked))}
            label="Tabular View"
            checked={tabularView}
            paddingBottom={1}
          />
        </Grid>
        {activeTab === REPORT_TYPE.PACE_TIME_WIDGET &&
          (tabularView ? (
            <PaceTimeTabularWidget setIsTimeLoaded={setIsLoaded} />
          ) : (
            <PaceTimeGraphicalWidget setIsTimeLoaded={setIsLoaded} />
          ))}
        {activeTab === REPORT_TYPE.PACE_SEGMENT_WIDGET &&
          (tabularView ? (
            <PaceSegmentTabularWidget setIsSegmentLoaded={setIsLoaded} />
          ) : (
            <PaceSegmentGraphicalWidget setIsSegmentLoaded={setIsLoaded} />
          ))}
        {activeTab === REPORT_TYPE.PACE_HOTEL_WIDGET &&
          (tabularView ? (
            <PaceHotelTabularWidget setIsHotelLoaded={setIsLoaded} />
          ) : (
            <PaceHotelGraphicalWidget setIsHotelLoaded={setIsLoaded} />
          ))}
      </Grid>
    </>
  );
};
//
export default Widget;
